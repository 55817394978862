//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
   data() {
    return {
      list: [],
      loading: false,
      finished: false,
      monthAgo:0,
      total:99,
      pager: {
        pageIndex: 1,
        pageSize: 10,
        rowsCount: 99,
        start: 0
      }
    };
  },
  created() {
  },
  methods: {
    getDateTime() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth()
    let startTime = new Date(year, month - this.monthAgo, 1).valueOf();
    let endTime = new Date(year, month  - this.monthAgo + 1, 0).valueOf();
    return {
      b_time:this.timestampToTime(startTime) + ' 00:00:00',
      e_time:this.timestampToTime(endTime)+ ' 23:59:59',
    }
  },
  timestampToTime(timestamp) {
      var date =new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() +'-';
      var M = (date.getMonth() +1 <10 ?'0' + (date.getMonth() +1) : date.getMonth() +1) +'-';
      var D = (date.getDate() +1 <10 ?'0' + date.getDate() : date.getDate());
      return Y +M +D;
  },
  getData() {
      let postData = {
      }
      this.axios
        .get(
            "/" + this.$ajaxPrefix.consumer + "/wxcp/brand/action/list",postData
        )
        .then((res) => {
          // this.$toast.clear();
          console.log(res)
          if (res.data.code == 0) {
            res.data.data.map(v =>{
              const now = new Date().getTime();
              if(v.status == 1){
                 v.state = '进行中'
                if(this.$moment(v.startTime).valueOf() > now){
                  v.state = '未开始'
                }
                if(this.$moment(v.endTime).valueOf() < now){
                  v.state = '已结束'
                }
              }else{
                v.state = '已结束'
              }
            })
            this.list = res.data.data
            // 加载状态结束
            this.loading = false;
            this.finished = true;
          } else {
             this.loading = false;
            this.finished = true;
            this.$toast({ message: "暂无活动", duration: 2000 });
          }

        });
    },
  },
  mounted() {
    document.title = "活动列表";
  },
};
